import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../common.css';
import './upload-dialog.css';
import {Modal, ProgressBar} from "react-bootstrap";
import axios from "axios";
import { Store } from 'react-notifications-component';

class DialogUploadFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            projectId: props.projectId,
            show: true,
            file: null,
            isReady: false,
            isUploading: false
        }

        this.handleFileLoading = this.handleFileLoading.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleFileLoading(event) {
        if (event.target.value) {
            this.setState({
                file: event.target.files[0],
                isReady: true
            });
        }
    }

    handleClose() {
        this.props.onClose();
    }

    handleSubmit() {
        this.setState({
            isUploading: true
        });
        const notificationSuccess = {
            title: this.state.file.name,
            message: 'File successfully uploaded',
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        }
        const notificationError = {
            ...notificationSuccess,
            message: 'Error occurred while uploading this file',
            type: 'danger'
        }
        const url = process.env.REACT_APP_DOMAIN + '/api/projects/' + this.state.projectId + '/reports';
        const formData = new FormData();
        formData.append('file', this.state.file)
        const requestConfig = {
            headers: {
                'content-type': 'multipart/form-data',
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        axios.post(url, formData, requestConfig)
            .then(response => {
                if (response.data.status === 'success') {
                    this.props.onDataUpdated();
                    Store.addNotification(notificationSuccess);
                    this.handleClose();
                } else {
                    Store.addNotification(notificationError);
                }
                this.setState({
                    isUploading: false
                });
            })
            .catch(() => {
                Store.addNotification(notificationError);
                this.setState({
                    isUploading: false
                });
            })
    }

    render() {
        return (
            <Modal show={this.state.show} onHide={this.handleClose}>
                <Modal.Header>
                    <Modal.Title>Upload data file</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <input type='file' className='form-control' onChange={this.handleFileLoading} disabled={this.state.isUploading}/>
                </Modal.Body>

                <Modal.Footer>
                    <button className='btn btn-secondary' onClick={this.handleClose}>Cancel</button>
                    <div className='button-submit-holder'>
                        {this.state.isUploading ?
                            <ProgressBar animated now={100} />
                            :
                            <button className='btn btn-primary'
                                    onClick={this.handleSubmit}
                                    disabled={!this.state.isReady}>Submit
                            </button>
                        }
                    </div>

                </Modal.Footer>
            </Modal>
        );
    }
}

export default DialogUploadFile