import React, {Component} from 'react'
import axios from "axios";
import {Store} from "react-notifications-component";


export default class ParseRoblox extends Component<any, any> {

    private timerID: any;
    public data: any;
    public submitedData: any;

    constructor(props: any) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.data = {
            formDisabled: false,
            buttonDisabled: true,
            content_type: 0,
            search_phrase: null,
            posts_num: 0,
            telegram_id: null,
        }
        this.state = {
            token: props.token,

            formDisabled: this.data.formDisabled,
            buttonDisabled: this.data.buttonDisabled,
            content_type: this.data.content_type,
            search_phrase: this.data.search_phrase,
            posts_num: this.data.posts_num,
            telegram_id: this.data.telegram_id,
        }
    }

    readyForSubmit() {
        console.log('this.data.content_type != 0', this.data.content_type != 0)
        console.log('this.data.search_phrase != null', this.data.search_phrase != null)
        console.log('this.data.posts_num != 0', this.data.posts_num != 0)
        console.log('this.data.telegram_id != null', this.data.telegram_id != null)
        console.log('this.data != this.submitedData', this.data != this.submitedData)
        return (this.data.content_type != 0
            && this.data.search_phrase != null
            && this.data.posts_num != 0
            && this.data.telegram_id != null && this.data != this.submitedData);
    }

    handleChange(event: any) {
        console.log(event.target.id, event.target.value)
        var id = event.target.id
        this.data[id] = event.target.value
        this.setState(this.data)

        if (this.readyForSubmit()) {
            this.setState({
                formDisabled: false,
                buttonDisabled: false
            })
        } else {
            this.setState({
                formDisabled: false,
                buttonDisabled: true
            })
        }
    }

    handleClick(e: any) {
        e.preventDefault()
        this.setState({
            formDisabled: true,
            buttonDisabled: true,
        })

        if (this.readyForSubmit()) {
            const notificationError = {
                title: 'Error',
                message: 'Parsing is unavailable',
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            }

            const url = process.env.REACT_APP_DOMAIN + '/api/contents';
            const config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + this.state.token
                }
            }

            this.submitedData = this.data;
            axios.post(url, {
                content_type: this.data.content_type,
                keyword: this.data.search_phrase,
                apps_count: this.data.posts_num,
                telegram: this.data.telegram_id
            }, config)
                .then(response => {
                    console.log(response)
                    if (response.status !== 200) {
                        // @ts-ignore
                        Store.addNotification(notificationError);
                    }
                })
                .catch(() => {
                    // @ts-ignore
                    Store.addNotification(notificationError);
                });
        } else {
            console.log('not ready')
        }
        /*if (this.state.appName) {
            this.setState({
                    disabled: true
                }
            )


        }*/
    }

    render() {
        return (
            <form onSubmit={this.handleClick}>
                <div className="row">

                    <h1>Спарсить контент</h1>
                    <div className="col-2">
                        <label htmlFor="content_type" className="form-label">Тип контента</label>
                        <select
                            className="form-select form-select-lg mb-3"
                            aria-label=".form-select-lg example"
                            id="content_type"
                            onChange={this.handleChange}
                            disabled={this.state.formDisabled}>
                            <option selected value="0">Выбрать..</option>
                            <option value="game">Игра</option>
                            <option value="skin">Скин</option>
                        </select>
                    </div>
                    <div className="col-4">
                        <label htmlFor="search_phrase" className="form-label">Поисковая фраза</label>
                        <input
                            type="text"
                            className="form-control"
                            id="search_phrase"
                            onChange={this.handleChange}/>
                    </div>
                    <div className="col-2">
                        <label htmlFor="posts_num" className="form-label">Кол-во контента для парсинга</label>
                        <input
                            type="number"
                            className="form-control"
                            id="posts_num"
                            onChange={this.handleChange}
                            disabled={this.state.formDisabled}/>
                    </div>
                    <div className="col-4">
                        <label htmlFor="telegram_id" className="form-label">ID телеграма (для отправки отчета)</label>
                        <input
                            type="integer"
                            className="form-control"
                            id="telegram_id"
                            onChange={this.handleChange}
                            disabled={this.state.formDisabled}/>
                    </div>
                    <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={this.state.buttonDisabled}>Добавить
                    </button>
                </div>
            </form>
        )
    }
}