import React, {Component} from 'react'
import { Link } from "react-router-dom"
import { map } from 'underscore'

const SECTIONS = [
    { icon: '/images/overview.svg', title: 'Овервью', href: '/'},
    { icon: '/images/apps.svg', title: 'Приложения', href: '/apps'},
    { icon: '/images/group.svg', title: 'Аккаунты', href: '/accounts'},
]

export default class Nav extends Component {
    render() {
        return (
            <div className="col-xxl-2 col-md-4 navigation h-100 fixed-top">
                <div className="logo">
                    <div className="site-icon">
                        <svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.39957 8.13818H6.52344V18.2046C6.52344 19.7865 7.8177 21.0808 9.39957 21.0808H22.3421V18.2046H9.39957V8.13818Z" fill="#F6F5FA"/>
                            <path d="M26.656 0.947754H15.1515C13.5696 0.947754 12.2754 2.24201 12.2754 3.82388V12.4523C12.2754 14.0341 13.5696 15.3284 15.1515 15.3284H26.656C28.2379 15.3284 29.5322 14.0341 29.5322 12.4523V3.82388C29.5322 2.24201 28.2379 0.947754 26.656 0.947754ZM26.656 12.4523H15.1515V6.70001H26.656V12.4523Z" fill="#F6F5FA"/>
                            <path d="M3.64712 13.8904H0.770996V23.9568C0.770996 25.5387 2.06525 26.833 3.64712 26.833H16.5897V23.9568H3.64712V13.8904Z" fill="#F6F5FA"/>
                        </svg>

                    </div>
                    <span className="site-name">ДАШБОРД</span>
                </div>
                <ul className="list-group">
                    {map(SECTIONS, ({ icon, title, href }) => (
                        // с помощью компонента Link будет осуществляться
                        // навигация по разделам приложения
                        <Link key={title} className='list-group-item list-group-item-action' to={href}>
                            <img src={icon}/>
                            {title}
                        </Link>
                    ))}
                </ul>
            </div>
        )
    }
}