import React, {Component} from 'react'
import axios from "axios";
import {Store} from "react-notifications-component";

interface State {
    appName: string,
    disabled: boolean,
    app: {
        id: number,
        name: string,
        apiKey: string,
        postApiKey: string,
    }
}

export default class AddApp extends Component<any, any> {

    constructor(props: any) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.copyText = this.copyText.bind(this);

        this.state = {
            token: props.token,
            appName: "",
            appBundle: null,
            disabled: true,
            app: {
                id: null,
                name: null,
                apiKey: null,
                postApiKey: null,
            }
        }
    }

    handleChange(event: any) {
        switch(event.target.id) {
            case "app_name": {
                var disabled = true
                if (event.target.value){
                    disabled = false
                }

                this.setState({
                        appName: event.target.value,
                        disabled: disabled
                    }
                )

                break;
            }
            case "app_bundle": {
                this.setState({
                        appBundle: event.target.value,
                    }
                )
                break;
            }
        }
    }

    handleClick(e: any) {
        e.preventDefault()

        if (this.state.appName) {
            this.setState({
                    disabled: true
                }
            )

            const url = process.env.REACT_APP_DOMAIN + '/api/apps';
            const config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: 'Bearer ' + this.state.token
                }
            }

            axios.post(url, {
                name: this.state.appName,
                bundle: this.state.appBundle
            }, config)
                .then(response => {
                    console.log(response)
                    if (response.status === 200) {
                        this.setState({
                                app: {
                                    id: response.data.appmetrica_id,
                                    name: response.data.name,
                                    apiKey: response.data.api_key,
                                    postApiKey: response.data.post_api_key
                                }
                            }
                        )
                    }

                    this.setState({
                            disabled: false
                        }
                    )
                })
                .catch(() => {
                    const notificationError = {
                        title: 'Error',
                        message: 'App is not created',
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__fadeIn"],
                        animationOut: ["animate__animated", "animate__fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    }
                    // @ts-ignore
                    Store.addNotification(notificationError);
                });
        }
    }

    copyText(text:string, e: any) {
        e.preventDefault()
        console.log("copy text co clipboard")
        var textArea = document.createElement("textarea");

        // Place in the top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = "0";
        textArea.style.left = "0";

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = "0";

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of the white box if rendered for any reason.
        textArea.style.background = 'transparent';


        textArea.value = text;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Copying text command was ' + msg);
        } catch (err) {
            console.log('Oops, unable to copy');
        }

        document.body.removeChild(textArea);
    }

    render() {
        return (
            <div className="column">
                <div className="row">
                    <div className="col-12">
                        <h2>Добавить приложение</h2>
                    </div>
                </div>

                <div className="row mt-4">
                    <div className="col-4">
                        <form onSubmit={this.handleClick}>
                            <div className="mb-3">
                                <label htmlFor="app_name" className="form-label">Название приложения</label>
                                <input type="text" className="form-control" id="app_name" onChange={this.handleChange}/>
                                <div className="invalid-feedback d-block">
                                    Обязательное поле
                                </div>
                                <label htmlFor="app_name" className="form-label mt-2">Бандл</label>
                                <input type="text" className="form-control" id="app_bundle" onChange={this.handleChange}/>
                            </div>
                            <button type="submit" className="btn btn-primary" disabled={this.state.disabled}>Добавить</button>
                        </form>
                    </div>
                </div>

                {this.state.app.id &&
                    <div>
                        <div className="row mt-4">
                            <div className="col-4 mb-3">
                                <label className="form-label">App id:</label>
                                <input type="text" className="form-control" value={this.state.app.id} disabled/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 mb-3">
                                <label className="form-label">App name:</label>
                                <input type="text" className="form-control" value={this.state.app.name} disabled/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <label className="form-label">Api key:</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" value={this.state.app.apiKey} disabled/>
                                    <button className="btn btn-outline-secondary" type="button"
                                            onClick={(e) => this.copyText(this.state.app.apiKey, e)}>Copy
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <label className="form-label">Post api key:</label>
                                <div className="input-group mb-3">
                                    <input type="text" className="form-control" value={this.state.app.postApiKey} disabled/>
                                    <button className="btn btn-outline-secondary" type="button"
                                            onClick={(e) => this.copyText(this.state.app.postApiKey, e)}>Copy
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>

        )
    }
}