import React from 'react';

import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import {
    Route,
    Switch,
    Redirect
} from "react-router-dom"


import '../App.css';

import Overview from './pages/Overview'
import AppsList from './pages/AppsList'
import AddApp from './pages/AddApp'
import Nav from './pages/Nav'
import Accounts from './pages/Accounts'
import ParseRoblox from "./pages/ParseRoblox";

import Main from "./apps-analytics/main/main";
import Project from "./apps-analytics/project/project";
import Login from "./apps-analytics/login/login";
import Register from "./apps-analytics/register/register";
import AddAccount from './pages/AddAccount';


export default class App extends React.Component<any, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            fromDate: localStorage.getItem('fromDate') ? new Date(parseInt(localStorage.getItem('fromDate') as string)) : new Date(),
            toDate: localStorage.getItem('toDate') ? new Date(parseInt(localStorage.getItem('toDate') as string)) : new Date(),
            token: localStorage.getItem('token') ? localStorage.getItem('token') : ''
        }

        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleTokenUpdate = this.handleTokenUpdate.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleDateChange(fromDate: any, toDate: any) {
        this.setState({
            fromDate: fromDate,
            toDate: toDate
        }, () => {
            localStorage.setItem('fromDate', fromDate.getTime());
            localStorage.setItem('toDate', toDate.getTime());
        })
    }

    handleTokenUpdate(token: any) {
        this.setState({
            token: token
        }, () => {
            localStorage.setItem('token', token);
        })
    }

    handleLogout() {
        this.setState({
            token: ''
        }, () => {
            localStorage.setItem('token', '');
        })
    }

    render() {
        // @ts-ignore
        return (
            <div className="row h-100">
                <ReactNotifications />
                <div className="col-xxl-2 col-md-4">
                    {this.state.token !== '' ?
                        <Nav/>
                        :
                        <p/>
                    }

                </div>
                <div className="col-xxl-10 col-md-8 content">
                    <div className="row">
                        <Switch>
                            <Route path="/apps/add">
                                {this.state.token !== '' ?
                                    <AddApp
                                        token={this.state.token}/>
                                    :
                                    <Redirect to='/login/'/>
                                }
                            </Route>
                            <Route path="/apps">
                                {this.state.token !== '' ?
                                    <AppsList
                                        token={this.state.token}/>
                                    :
                                    <Redirect to='/login/'/>
                                }
                            </Route>
                            <Route path="/accounts/add">
                                {this.state.token !== '' ?
                                    <AddAccount
                                        token={this.state.token}/>
                                    :
                                    <Redirect to='/login/'/>
                                }
                            </Route>
                            <Route path="/accounts">
                                {this.state.token !== '' ?
                                    <Accounts
                                        token={this.state.token}/>
                                    :
                                    <Redirect to='/login/'/>
                                }
                            </Route>
                            <Route path="/parseRoblox">
                                {this.state.token !== '' ?
                                    <ParseRoblox
                                        token={this.state.token}/>
                                    :
                                    <Redirect to='/login/'/>
                                }
                            </Route>

                            <Route path='/analytics/projects/:id/'>
                                {this.state.token !== '' ?
                                    <div className={"content-area"}>
                                        <Project
                                            token={this.state.token}
                                            fromDate={this.state.fromDate}
                                            toDate={this.state.toDate}
                                            onDateChange={this.handleDateChange}/>
                                    </div>
                                    :
                                    <Redirect to='/login/'/>
                                }
                            </Route>

                            <Route path='/analytics/'>
                                {this.state.token !== '' ?
                                    <Main
                                        token={this.state.token}
                                        fromDate={this.state.fromDate}
                                        toDate={this.state.toDate}
                                        onDateChange={this.handleDateChange}/>
                                    :
                                    <Redirect to='/login/'/>
                                }
                            </Route>

                            <Route path='/login/'>
                                <div className='d-none'>
                                    <Login onTokenUpdate={this.handleTokenUpdate}/>
                                </div>
                            </Route>

                            <Route path='/register/'>
                                <Register/>
                            </Route>

                            <Route path='/404'>
                                {<p>Not found</p>}
                            </Route>

                            <Route path="/">
                                {this.state.token !== '' ? <Overview
                                    token={this.state.token}
                                    onLogout={this.handleLogout}/>
                                    :
                                    <Redirect to='/login/'/>
                                }
                            </Route>

                            <Redirect from='*' to='/404'/>
                        </Switch>
                    </div>
                </div>
            </div>
        );
    }
}
