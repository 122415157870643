import React, {Component} from 'react'
import {HotColumn, HotTable} from '@handsontable/react';
import axios from "axios";
import Handsontable from "handsontable";
import {Store} from "react-notifications-component";
import {Link} from "react-router-dom";

export default class Accounts extends Component<any, any> {
    private readonly data: any;
    private hotTableComponent: any;
    private id;

    constructor(props: any) {
        super(props);
        this.hotTableComponent = React.createRef();
        this.state = {
            token: props.token,
            settings: {
                data: {},
                colHeaders: ["ID", "Name", "Registred at", "Developer", "Proxy", "Card", "Phone", "Phone paid action", "Login", "Password", "Key password", "Status", "Comments"],
                dataSchema: {
                    appmetrica_id: null,
                    account: null,
                    name: null,
                    bundle: null,
                    host_code: null,
                    status: null,
                    ads: null,
                    installs: null,
                    crashes: null,
                },
                renderAllRows: true,
                viewportRowRenderingOffset: 600,
                startCols: 9,
                rowHeaders: true,
                dropdownMenu: true,
                filters: true,
                columnSorting: true,
                licenseKey: "non-commercial-and-evaluation",
            },
            columns: [
                {
                    readOnly: true,
                },
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {
                    type: 'dropdown',
                    source: ['DRAFT', 'MODERATION', 'PUBLISHED', 'DECLINED', 'BLOCKED', 'ARCHIVE']
                },
                {},
            ],
            minSpareRows: 1
        }
        this.id = 'hot';
        var self = this

        const url = process.env.REACT_APP_DOMAIN + '/api/accounts';
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }

        axios.get(url, config)
            .then(response => {
                console.log(response);

                var accountStatus: any;
                accountStatus = []
                response.data.forEach((element: any) => {
                    accountStatus[element.id] = element.status
                })
                this.setState({
                    settings: {
                        data: response.data,
                        cells: function (row: any, col: any, prop: any) {
                            var appID = self.hotTableComponent.current.hotInstance.getDataAtCell(row, 0)
                            var cellProperties = self.state.columns[col]

                            cellProperties.renderer = function (instance: any, td: any, row: any, col: any, prop: any, value: any, cellProperties: any) {
                                // @ts-ignore
                                Handsontable.renderers.TextRenderer.apply(this, arguments);

                                //td.style.background = '#CEC';

                                //switch (response.data[row].status) {

                                switch (accountStatus[appID]) {
                                    case "PUBLISHED":
                                        //td.className = 'bg-success'
                                        td.className = 'bg-success ' + appID
                                        break;
                                    case "BANNED":
                                    case "BLOCKED":
                                        td.className = 'bg-danger'
                                        break;
                                    case "DRAFT":
                                        td.className = 'bg-secondary'
                                        break;
                                    case "MODERATION":
                                        td.className = 'bg-warning'
                                        break;
                                    case "ARCHIVE":
                                        td.className = 'bg-light'
                                        break;
                                    case "DECLINED":
                                        td.className = 'bg-warning'
                                        break;
                                }

                                if (response.data[row].status === "PUBLISHED" && response.data[row].host_code === 404) {
                                    td.className = 'bg-attention'
                                }

                                if (response.data[row].installs >= 400 && response.data[row].ads === 0) {
                                    td.className = 'bg-info'
                                }
                            }

                            return cellProperties
                        },
                        afterChange: (changes: any) => {
                            // @ts-ignore
                            if (changes) {
                                changes.forEach(([row, prop, oldValue, newValue]: any) => {
                                    if (oldValue != newValue) {
                                        const notificationError = {
                                            title: 'Error',
                                            message: 'Changes are not committed',
                                            type: "danger",
                                            insert: "top",
                                            container: "top-right",
                                            animationIn: ["animate__animated", "animate__fadeIn"],
                                            animationOut: ["animate__animated", "animate__fadeOut"],
                                            dismiss: {
                                                duration: 5000,
                                                onScreen: true
                                            }
                                        }

                                        const url = process.env.REACT_APP_DOMAIN + "/api/accounts/" + response.data[row].id;
                                        const config = {
                                            headers: {
                                                Accept: 'application/json',
                                                Authorization: 'Bearer ' + this.state.token
                                            }
                                        }

                                        axios.put(url, {
                                            [prop]: newValue
                                        }, config).then(response => {
                                            console.log(response)
                                            if (response.status !== 200) {
                                                // @ts-ignore
                                                Store.addNotification(notificationError);
                                            }
                                        }).catch(error => {
                                            // @ts-ignore
                                            Store.addNotification(notificationError);
                                        });
                                    }
                                });
                            }


                        }
                    }
                })
            })
            .catch(() => {
                // window.location.href = "login"
            });
    }

    render() {
        const statusColumnSettings = {
            type: 'dropdown',
            source: ['DRAFT', 'MODERATION', 'PUBLISHED', 'DECLINED', 'BLOCKED', 'ARCHIVE']
        };

        return (
            <div id="hot-app" className="accounts container">
                <div className="row">
                    <div className="col-12">
                        <div className="col-12 d-flex flex-row">
                            <h2>Аккаунты</h2>

                            <Link to={"/accounts/add"}>
                                <button className="btn btn-outline-primary ms-3">Добавить аккаунт</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-12">
                        <HotTable ref={this.hotTableComponent} id={this.id} settings={this.state.settings}>
                            <HotColumn data="id"/>
                            <HotColumn data="name"/>
                            <HotColumn data="registred_at"/>
                            <HotColumn data="developer"/>
                            <HotColumn data="proxy"/>
                            <HotColumn data="card"/>
                            <HotColumn data="phone"/>
                            <HotColumn data="phone_last_action"/>
                            <HotColumn data="login"/>
                            <HotColumn data="password"/>
                            <HotColumn data="key_password"/>
                            <HotColumn data="status" setting={statusColumnSettings}/>
                            <HotColumn data="comment"/>
                        </HotTable>
                    </div>
                </div>

            </div>
        )
    }
}
