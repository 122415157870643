import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './../../common.css';
import {Modal} from 'react-bootstrap';
import DateRangeSelector from '../../common-components/date-range-selector/date-range-selector.js';
import axios from "axios";
import {Store} from 'react-notifications-component';

class DialogCreateProject extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            token: props.token,
            show: props.show,
            title: ''
        }

        this.hide = this.hide.bind(this);
        this.createProject = this.createProject.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
    }

    hide() {
        this.props.onClose();
    }

    createProject() {
        const notificationError = {
            title: 'Error',
            message: 'Unable to create project "' + this.state.title + '"',
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        }
        const notificationException = {
            ...notificationError,
            message: 'Error occured while creating project. Try again'
        }
        const notificationSuccess = {
            ...notificationError,
            title: 'Success',
            message: 'Project ' + this.state.title + ' successfully created',
            type: 'success'
        }
        const url = process.env.REACT_APP_DOMAIN + '/api/projects?name=' + this.state.title;
        const config = {
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + this.state.token
            }
        }
        axios.post(url, {}, config)
            .then(response => {
                    if (response.data.status === 'success') {
                        this.props.onCreate();
                        Store.addNotification(notificationSuccess)
                        this.hide();
                    } else {
                        Store.addNotification(notificationError)
                    }
                }
            )
            .catch(() => {
                Store.addNotification(notificationException)
            })
    }

    handleTitleChange(event) {
        this.setState({
            title: event.target.value
        });
    }

    render() {
        return (
            <Modal show={this.state.show} onHide={this.hide}>
                <Modal.Header>
                    <Modal.Title>Create new project</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <input type='text' className='form-control' placeholder='Project title'
                           onChange={this.handleTitleChange}/>
                </Modal.Body>

                <Modal.Footer>
                    <button className='btn btn-outline-secondary' onClick={this.hide}>Cancel</button>
                    <button className='btn btn-success' onClick={this.createProject}
                            disabled={this.state.title === ''}>Save
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }

}

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: props.token,
            showDialog: false,
            fromDate: props.fromDate,
            toDate: props.toDate,
            showDateRangePicker: false
        }

        this.handleCreateClick = this.handleCreateClick.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleCreateClick() {
        this.setState({
            showDialog: true
        });
    }

    handleCloseDialog() {
        this.setState({
            showDialog: false
        });
    }

    handleDateRangeChange(fromDate, toDate) {
        this.setState({
            fromDate: fromDate,
            toDate: toDate
        });
        this.props.onDateRangePicked(fromDate, toDate);
    }

    handleUpdate() {
        this.props.onCreateProject();
    }

    render() {
        return (
            <div className='flex centered-vertical spaced-between'>
                <div className='flex centered-vertical'>
                    <h1>Analytics</h1>
                    <button className='btn btn-outline-success button-minified button-create-project'
                            onClick={this.handleCreateClick}>Create project
                    </button>
                    <DialogCreateProject
                        key={this.state.showDialog.toString()}
                        token={this.state.token}
                        show={this.state.showDialog}
                        onClose={this.handleCloseDialog}
                        onCreate={this.handleUpdate}/>
                </div>
                <DateRangeSelector
                    fromDate={this.state.fromDate}
                    toDate={this.state.toDate}
                    onChange={this.handleDateRangeChange}/>
            </div>
        );
    }
}

export default Header