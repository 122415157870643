import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './register.css';
import './../common.css';
import {Link} from "react-router-dom";
import axios from "axios";

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            confirmedPassword: ''
        }

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleConfirmedPasswordChange = this.handleConfirmedPasswordChange.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
    }

    handleEmailChange(event) {
        this.setState({
            email: event.target.value
        })
    }

    handleConfirmedPasswordChange(event) {
        this.setState({
            confirmedPassword: event.target.value
        })
    }

    handlePasswordChange(event) {
        this.setState({
            password: event.target.value
        })
    }

    handleRegister() {
        const url = process.env.REACT_APP_DOMAIN + '/api/register'
        const requestConfig = {
            headers: {
                'accept': 'application/json',
                'content-type': 'application/json'
            }
        }
        const requestBody = {
            email: this.state.email,
            password: this.state.password
        }
        axios.post(url, requestBody, requestConfig)
            .then(response => {
                if (response.data.status === 'success') {
                    window.location.href = window.location.href.replace("/register", "");
                } else {
                    alert('Registration error')
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {
        return (
            <div className='center-aligned'>
                <h1>AMS-Soft Dashboard</h1>
                <form className='form-register'>
                    <input type="email" className="form-control" placeholder="Email" onChange={this.handleEmailChange}/>
                    <input type="password" className="password form-control" placeholder="Password"
                           onChange={this.handlePasswordChange}/>
                    <input type="password" className="confirm-password form-control" placeholder="Confirm password"
                           onChange={this.handleConfirmedPasswordChange}/>
                    {this.state.password !== this.state.confirmedPassword ?
                        <div className='warning'>Passwords don't match</div>
                        : null}
                    <div className='center-aligned'>
                        <button type="button" className="button-register btn btn-primary"
                                onClick={this.handleRegister}
                                disabled={(this.state.password !== this.state.confirmedPassword)
                                    || this.state.email === '' || this.state.password === ''}>Зарегистрироваться
                        </button>
                    </div>
                    <div className='center-aligned login-holder'>
                        <Link className='link-login' to='/login/'>Войти</Link>
                    </div>
                </form>
            </div>
        );
    }
}

export default Register